import Vue from "vue";
import App from "./App.vue";
import VueI18n from "vue-i18n";
import router from "./router";
import langages from "./i18n";
const messages = Object.assign(langages);
Vue.config.productionTip = false;
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  messages
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
