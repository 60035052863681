import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";

const languages = {
  en: en,
  es: es,
  fr: fr
};

export default languages;
