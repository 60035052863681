import VueRouter, { RouteConfig } from "vue-router";
import Vue from "vue";
import ASTApproval from "@/components/ASTApproval.component.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/approvals",
    name: "approval",
    component: ASTApproval
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
