import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// script relies on google api js imported straight from export.html
export const env = process.env.NODE_ENV;

export let CLIENT_ID = "373400409133-bsrm3uva8o9eh4r26qb7ucii9ah7q8la.apps.googleusercontent.com";
export let API_KEY = "AIzaSyD_k3viObMlhLUMy87DKHFsdh7HFW81zHs";

export let firebaseConfig = {
  apiKey: API_KEY,
  authDomain: "antibiogo-dev-7ebbe.firebaseapp.com",
  projectId: "antibiogo-dev-7ebbe",
  storageBucket: "antibiogo-dev-7ebbe.appspot.com",
  messagingSenderId: "373400409133",
  appId: "1:373400409133:web:b4d4929edbf77ba9bcd4a4",
  measurementId: "G-09KYCNNN5M"
};

//Override values if production
if (env === "production") {
  CLIENT_ID = "6879902360-pf750qugm6clsvd7a4emg7mnj8235b1e.apps.googleusercontent.com";
  API_KEY = "AIzaSyC2u4Dl1B7rRpOD1ycNrpUINXZIwpY-gWg";

  firebaseConfig = {
    apiKey: API_KEY,
    authDomain: "antibiogo-prod.firebaseapp.com",
    projectId: "antibiogo-prod",
    storageBucket: "antibiogo-prod.appspot.com",
    messagingSenderId: "6879902360",
    appId: "1:6879902360:web:b2382930b9ecf477bceeed",
    measurementId: "G-NBVDNRNMEP"
  };
}

export const DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];

export const SCOPES = "https://www.googleapis.com/auth/drive.file";

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();

export const storage = firebaseApp.storage();

export const functions = firebaseApp.functions("europe-west1");
