import Vue from "vue";
import Component from "vue-class-component";
import approval from "@/utils/approval";
import Loader from "@/components/Loader.vue";
import { SiriMapping } from "@/components/SiriMapping";

const REGEX_MIC_OR_G = /\s+(?:\(*[mM][iI][cC]\)*|[\d.-]+\s*µ[gG])/;
const REGEX_G = /\s+[\d.-]+\s*µ[gG]/;

@Component({
  components: { Loader }
})
export default class ASTApproval extends Vue {
  private cloudId = "";
  private loaded = false;
  private approved = false;
  private invalidId = false;

  private showSelective = true;
  private selectivePresent = false;
  private showComplex = false;
  private bodyMargin = 24;

  private ASTMeta: any = null;

  private pelletNamesImage = "";

  private performedDate = "";
  private inferredAntibiotics = [];
  private testedAntibiotics = [];
  private filteredAntibiotics = [];
  private alerts = [];
  private notes = {};
  private notesLength = 0;
  private technicianInput = "";
  private complexInterations = [];
  private micInputs = [];

  mounted() {
    window.addEventListener("resize", () => {
      this.calcBodyMargins();
    });
    this.calcBodyMargins();

    const urlParams = new URLSearchParams(window.location.search);
    this.cloudId = urlParams.get("approve") || "";
    this.loadAXFFile(this.cloudId);
    this.getApprovalStatus(this.cloudId);
  }

  async approve() {
    try {
      this.approved = await approval.approve(this.cloudId);
    } catch (e) {
      console.error(e);
      this.invalidId = true;
    }
  }

  formatAntibioticsName(name: string) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  formatAntibioticsLabel(sir: string, siri: string): string {
    let esVal = siri;
    if (!esVal) {
      esVal = sir;
    }
    return SiriMapping[esVal];
  }

  formatAdministrationType(type: string) {
    switch (type) {
      case "ORAL":
        return "Oral";
      case "ORAL_FEMALE":
        return "Oral (Adults - Female)";
      case "ORAL_MALE":
        return "Oral (Adults - Male)";
      default:
        return type;
    }
  }

  isS(sir: string, siri: string): boolean {
    return this.formatAntibioticsLabel(sir, siri) === "S";
  }

  compareAbgs(a: any, b: any) {
    const getPriority = (abg: any) => {
      const short = this.formatAntibioticsLabel(abg.sir, abg.siri);
      switch (short) {
        case "S":
          return 0;
        case "I":
          return 1;
        case "R":
          return 2;
        case "N/A":
          return 3;
        default:
          return 4;
      }
    };

    const aPrior = getPriority(a);
    const bPrior = getPriority(b);

    if (aPrior === bPrior) {
      return a.name < b.name ? -1 : 1;
    } else {
      return aPrior < bPrior ? -1 : 1;
    }
  }

  formatAtbNames(atbs: any) {
    atbs.forEach((atb: any) => {
      // Special case because of i2a implementation, if "ATB_NAME (MIC)" has a breakpoint it's SIRI will be "MIC = @mic mg/l SIRI"
      if (atb.cmi > 0 && atb.siri === "MIC = @mic mg/l") atb.siri = atb.sir;
      // atb name renaming
      let atbNameFormated = this.formatAntibioticsName(atb.name);
      if (atb.cmi > 0 || atb.siri === "request M.I.C.") {
        let strippedAtbName = atbNameFormated.replace(REGEX_MIC_OR_G, "");
        atb.name = `${strippedAtbName} (MIC)`;
      } else {
        let strippedAtbName = atbNameFormated.replace(REGEX_G, "");
        atb.name = strippedAtbName.replace("(mic)", "(MIC)");
      }
    });
  }

  formatAntibioticName(antibiotic: string) {
    return antibiotic.replace(REGEX_MIC_OR_G, "");
  }

  populateAntibiotics(report: any) {
    function inferredTest(atb: any) {
      return atb.sir === "" || (atb.inhibitionZoneDiameter === -1.0 && atb.cmi === -1);
    }

    const sortAbgArray = (a: any) => {
      a.sort((a: any, b: any) => this.compareAbgs(a, b));
    };

    // handling locale change (could be improved?)
    if (/^es\b/.test(navigator.language)) {
      this.$root.$i18n.locale = "es";
    } else if (/^fr\b/.test(navigator.language)) {
      this.$root.$i18n.locale = "fr";
    }
    this.formatAtbNames(report.antibiotics.onlyAllowed);

    this.inferredAntibiotics = report.antibiotics.onlyAllowed.filter((el: any) => inferredTest(el));
    this.testedAntibiotics = report.antibiotics.onlyAllowed.filter((el: any) => !inferredTest(el));
    const testedAntibioticWithMicRequest: never[] = JSON.parse(JSON.stringify(this.testedAntibiotics))
      .filter((atb: any) => atb.siri === "request M.I.C.")
      .map((atb: any) => {
        atb.name = this.formatAntibioticName(atb.name);
        atb.siri = atb.sir;
        return { ...atb };
      });
    this.testedAntibiotics.push(...testedAntibioticWithMicRequest);

    sortAbgArray(this.inferredAntibiotics);
    sortAbgArray(this.testedAntibiotics);
    this.filteredAntibiotics = report.antibiotics.filtered;

    Object.keys(this.filteredAntibiotics).forEach((el: any) => {
      const array: any = this.filteredAntibiotics[el];
      sortAbgArray(this.filteredAntibiotics[el]);
      if (array.length > 0) {
        this.selectivePresent = true;
        this.formatAtbNames(array);
      }
    });
  }

  loadData(data: string) {
    const parsedData = JSON.parse(data);
    const report = JSON.parse(parsedData.reportJsonStr);
    const ASTData = JSON.parse(parsedData.axfJsonStr);

    this.ASTMeta = ASTData.metadata;

    this.complexInterations = JSON.parse(parsedData.complexInteractionsJsonStr);

    this.pelletNamesImage = "data:image/jpg;base64," + ASTData.pelletNamesImage;

    const createDate = new Date(this.ASTMeta.createdAt);
    this.performedDate = createDate.toLocaleDateString();

    this.populateAntibiotics(report);

    if (this.complexInterations.length > 0) this.showComplex = true;

    if (!this.selectivePresent) this.showSelective = false;

    this.technicianInput = report.notes.technicianInput;
    this.alerts = report.alerts;
    var reportNotes = report.notes.notes;
    if (report.notes.labNotes !== undefined) {
      reportNotes = report.notes.labNotes;
    }
    this.notes = reportNotes;
    this.notesLength = Object.keys(reportNotes).length;
    if (this.technicianInput) this.notesLength += 1;
    this.micInputs = report.notes.micNotes;

    this.loaded = true;
  }

  calcBodyMargins() {
    const width = window.innerWidth;
    const DEFAULT_BODY_MARGINS = 24;
    const maxWidth = 552 + 2 * DEFAULT_BODY_MARGINS;
    if (width > maxWidth) {
      this.bodyMargin = 24 + (width - maxWidth) / 2;
    } else {
      this.bodyMargin = 24;
    }
  }

  async loadAXFFile(cloudId: string) {
    try {
      /*
      To make it work you need to configure CORS for your Firebase Project
      More details:
      https://firebase.google.com/docs/storage/web/download-files#cors_configuration
    */
      const axfDownloadUrl = await approval.getAXFDownloadUrl(cloudId);
      console.log(axfDownloadUrl);
      const xhr = new XMLHttpRequest();
      xhr.responseType = "text";
      xhr.onload = () => {
        this.loadData(xhr.response);
      };
      xhr.open("GET", axfDownloadUrl);
      xhr.send();
    } catch (e) {
      this.invalidId = true;
      console.error("Get download URL failed with: ", e);
    }
  }

  async getApprovalStatus(cloudId: string) {
    try {
      const isApproved = await approval.getApprovalStatus(cloudId);
      this.approved = isApproved || "";
    } catch (e) {
      this.invalidId = true;
      console.error("Unable to fetch approval status with error: ", e);
    }
  }
}
