import { db, storage, functions } from "@/firebase";

export default {
  async approve(cloudId: string): Promise<boolean> {
    await db
      .collection("approvals")
      .doc(cloudId)
      .update({ approved: true });
    return true;
  },
  async getAXFDownloadUrl(cloudId: string): Promise<string> {
    const path = "approvals/" + cloudId + ".axf";
    return await storage
      .ref()
      .child(path)
      .getDownloadURL();
  },
  async getApprovalStatus(cloudId: string): Promise<any> {
    const approvalStatus = await db
      .collection("approvals")
      .doc(cloudId)
      .get();
    if (approvalStatus.data()) {
      return Promise.resolve(approvalStatus.data()?.approved);
    } else {
      return Promise.reject(Error("Unable to retrieve approval status"));
    }
  }
};
