export const SiriMapping: { [key: string]: string } = {
  "S": "S",
  "SDD": "SDD",
  "I": "I",
  "R": "R",
  "NI": "N/A",
  "PS": "N/A",
  "NA": "N/A",
  "N/A": "N/A",
  "SUSCEPTIBLE DOSE DEPENDENT": "SDD",
  "Diminished susceptibility": "R",
  "High level resistance": "High level R",
  "Intermediate level resistance": "R",
  "Intermediate": "I",
  "Low level resistance": "Low level R",
  "Resistant": "R",
  "SUSCEPTIBLE": "S",
  "SUSCEPTIBLE, INCREASED EXPOSURE": "I",
  "S, INCREASED EXPOSURE": "I",
  "Susceptible \"with reserve\"": "S",
  "S with reserve": "S with reserve",
  ".": "N/A",
  "Cannot be interpeted": "N/A",
  "In progress": "N/A",
  "MIC = @mic mg/l": "N/A",
  "NOT CORRECT": "Interpretation not applicable",
  "No Eucast Breakpoint": "No Eucast Breakpoint",
  "No Breakpoint" :"No CLSI Breakpoint",
  "request M.I.C.": "N/A",
  "ABSENCE Mechanism R": "Absence of Resistance"
};